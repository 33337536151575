.projectExperience {
    background-color: #ffffff;
    color: rgb(245, 4, 4);
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

.tile-container {
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;

}