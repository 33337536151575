.App {
  text-align: center;
}

.App-header {
  background-color: #406498;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* .link-container {
  display: flex;
} */

.link-box {
  display: inline-block;
  margin: 10px;
  padding: 10px;
  background-color: #f2f2f2;
  border: 1px solid #ddd;
}

.button-link {
  display: inline-block;
  border: 1px solid #ccc;
  background-color: #fff;
  color: #000;
  padding: 8px 16px;
  margin: 10px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.button-link:hover {
  background-color: #f2f2f2;
}